export const initialStudentData = [
    { key: "1", name: "ElSeify", age: 22, email: "ElSeify.reda@example.com", hidden: false },
    { key: "2", name: "Sara Ahmed", age: 19, email: "sara.ahmed@example.com", hidden: true },
    { key: "3", name: "Ali Mohamed", age: 25, email: "ali.mohamed@example.com", hidden: false },
    { key: "4", name: "Nour Khaled", age: 21, email: "nour.khaled@example.com", hidden: true },
    { key: "5", name: "Amina Hassan", age: 23, email: "amina.hassan@example.com", hidden: false },
    { key: "6", name: "Omar Farouk", age: 27, email: "omar.farouk@example.com", hidden: false },
    { key: "7", name: "Layla Salim", age: 20, email: "layla.salim@example.com", hidden: true },
    { key: "8", name: "Hassan Ali", age: 30, email: "hassan.ali@example.com", hidden: false },
    { key: "9", name: "Zainab Ahmed", age: 24, email: "zainab.ahmed@example.com", hidden: false },
    { key: "10", name: "Youssef Ibrahim", age: 26, email: "youssef.ibrahim@example.com", hidden: true },
    { key: "11", name: "Fatima Ali", age: 22, email: "fatima.ali@example.com", hidden: false },
    { key: "12", name: "Ibrahim Mohamed", age: 29, email: "ibrahim.mohamed@example.com", hidden: true },
    { key: "13", name: "Rania Omar", age: 28, email: "rania.omar@example.com", hidden: false },
    { key: "14", name: "Khaled Ahmed", age: 31, email: "khaled.ahmed@example.com", hidden: false },
    { key: "15", name: "Mona Khaled", age: 25, email: "mona.khaled@example.com", hidden: true },
  ];